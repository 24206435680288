import * as React from "react"
import { Card, Stack, Button, Offcanvas } from "react-bootstrap"

import { navigate } from "gatsby-link"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const IMAGE_HEIGHT = 180
const handleDragStart = e => e.preventDefault()

const items = [
  <StaticImage
    src="../sfiggy/Sfiggy1.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #1"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy2.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #2"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy3.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #3"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy5.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #4"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy6.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #5"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy7.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #6"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy10.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #7"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy81.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #8"}
  />,
  <StaticImage
    src="../sfiggy/Sfiggy91.jpg"
    height={IMAGE_HEIGHT}
    onDragStart={handleDragStart}
    alt={"Sfiggy #9"}
  />,
]

const IndexPage = () => {
  const [show, setShow] = React.useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Layout>
        <Seo title="Home" />
        <Stack gap={5}>
          <AliceCarousel
            items={items}
            animationType="fadeout"
            animationDuration={5000}
            disableButtonsControls
            disableDotsControls
            autoPlay
            autoWidth
            infinite
            mouseTracking
          />
          "The Sfiggy epos is just a trip back and forth in time, with no maps,
          with no paths, with no preset chronological order. Adamic hero of
          himself, he dares, risks, crashes. Then he stops, mends the wounds and
          start again."
          <footer className="blockquote-footer">
            From the text by{" "}
            <a
              href="https://wsimag.com/it/authors/125-giovanna-lacedra"
              target="_blank"
            >
              Giovanna Lacedra
            </a>
          </footer>
          <Card>
            <Card.Header as="h5">About Sfiggy</Card.Header>
            <Card.Body>
              <Card.Text>
                There's a little, white, scarred and funny poor thing, out
                there. Sfiggy's his name … maybe 'cause he is a looser? Who's
                Sfiggy? Sfiggy is a (anti)hero of our times but he is timeless:
                he doesn't belong to any era and belongs to all of them. Sfiggy
                is an icon of himself in any time. Sfiggy is all of us! He is
                the part of our souls that wants to remain a child so to still
                be amazed by everyday life, but facing it with the
                self-consciousness and responsibility of an adult. Sfiggy
                doesn't want to forget, he wants to remember all the
                experiences: good or bad, joys and sorrows, successes,
                adversities and passions. All of them are making us what we are.
                Sfiggy tells us our own story, without forgetting to face
                everything with a smile.
              </Card.Text>
              <Button
                variant="primary"
                className="me-2"
                onClick={() => {
                  navigate("/collection/page/1/")
                  return false
                }}
              >
                Explore Collection
              </Button>
              <Button variant="primary" className="me-2" onClick={handleShow}>
                Watch Intro Video
              </Button>
            </Card.Body>
          </Card>
        </Stack>
      </Layout>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Sfiggy NFT Launch Video</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <video width="360" height="636" controls>
            <source src="intro.mp4" type="video/mp4"></source>
          </video>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default IndexPage
